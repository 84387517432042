"use client"

import * as AccordionPrimitive from "@radix-ui/react-accordion"
import type { ComponentProps } from "react"
import { cx } from "~/lib/cva"

export const Accordion = AccordionPrimitive.Root
export const AccordionItem = AccordionPrimitive.Item
export const AccordionTrigger = AccordionPrimitive.Trigger

export const AccordionContent = ({
  className,
  ...props
}: ComponentProps<typeof AccordionPrimitive.Content>) => (
  <AccordionPrimitive.Content
    className={cx(
      "overflow-hidden",
      "data-[state=open]:animate-slide-down data-[state=closed]:animate-slide-up",
      className,
    )}
    {...props}
  />
)
