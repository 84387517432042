import Link from "next/link"
import type { ComponentProps } from "react"
import { IconLogo } from "~/components/ui/icons/logo"
import { Stack } from "~/components/ui/stack"
import { META_NAME } from "~/lib/config"
import { cx } from "~/lib/cva"

export const Logo = ({ className, ...props }: ComponentProps<typeof Stack>) => {
  return (
    <Stack size="sm" className={cx("group/logo flex-nowrap", className)} asChild {...props}>
      <Link href="/">
        <IconLogo />
        <span className="font-semibold text-base">{META_NAME}</span>
      </Link>
    </Stack>
  )
}
