import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/app/_components/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/vercel/path0/components/nav.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Geist\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-geist\",\"subsets\":[\"latin\"]}],\"variableName\":\"geistFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-general\",\"display\":\"swap\",\"src\":[{\"path\":\"../public/fonts/GeneralSans-Variable.woff2\",\"weight\":\"400 900\",\"style\":\"normal\"}]}],\"variableName\":\"generalFont\"}");
