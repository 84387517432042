"use client"

import { IconMenu2, IconX } from "@tabler/icons-react"
import Link from "next/link"
import { useEffect, useState } from "react"
import { CtaButton } from "~/components/cta-button"
import { Nav, type NavItem } from "~/components/nav"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui/accordion"
import { Container } from "~/components/ui/container"
import { Logo } from "~/components/ui/logo"
import { env } from "~/env"
import { cx } from "~/lib/cva"

const navItems: NavItem[] = [
  { title: "Pricing", href: "/pricing", segment: "pricing" },
  { title: "Log in", href: env.NEXT_PUBLIC_SUPERSTASH_APP_URL, segment: "login", target: "_blank" },
]

export const Header = () => {
  const [isShrunk, setShrunk] = useState(false)
  const [value, setValue] = useState("")

  useEffect(() => {
    const handler = () => {
      setShrunk(() => {
        const bodyScrollTop = document.body.scrollTop || document.documentElement.scrollTop
        return bodyScrollTop > 20
      })
    }

    // Run once on mount
    handler()

    // Listen for scroll events
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [])

  return (
    <Accordion type="single" collapsible value={value} onValueChange={setValue}>
      <AccordionItem
        value="nav"
        className={cx(
          "fixed top-0 z-40 w-full border-b transition-all",
          "data-[state=open]:bg-white/95 data-[state=open]:backdrop-blur",
          isShrunk ? "bg-white/95 backdrop-blur" : "border-b-transparent",
        )}
      >
        <Container
          className={cx(
            "flex items-center gap-4 h-16 transition-[height] md:h-20 md:gap-6 lg:h-24 lg:gap-8",
            isShrunk && "md:!h-16",
          )}
        >
          <AccordionTrigger className="group rounded-md p-1 -m-1 text-foreground/75 hover:bg-foreground/5 hover:text-foreground md:hidden">
            <span className="sr-only">Toggle menu</span>
            <IconMenu2 className="group-data-[state=open]:hidden" />
            <IconX className="group-data-[state=closed]:hidden" />
          </AccordionTrigger>

          <Logo className="mr-auto hover:opacity-80" />

          <Nav items={navItems} className="hidden font-medium text-sm sm:text-base md:contents" />

          <CtaButton size="md">Sign Up</CtaButton>
        </Container>

        <AccordionContent className="space-y-1 pb-3 pt-1 md:hidden">
          <Container>
            {navItems.map((item, index) => (
              <Link
                key={index}
                href={item.href}
                className="text-neutral-500 hover:text-foreground block py-2 font-medium"
                onClick={() => setValue("")}
              >
                {item.title}
              </Link>
            ))}
          </Container>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
