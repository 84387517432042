import type { ComponentProps } from "react"

export const IconLogo = ({ ...props }: ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="currentColor"
      role="img"
      aria-label="Logo"
      {...props}
    >
      <path d="M16.9386 0C17.506 0 17.9922 0.476288 17.9922 1.03196V16.5907C18.0328 16.8685 17.9112 17.1464 17.7086 17.3448C17.506 17.5433 17.2628 17.6226 16.9792 17.6623L1.0536 17.6226C0.486277 17.6226 0 17.1464 0 16.5907V8.81132C0 8.25565 0.486277 7.77937 1.0536 7.77937C1.62092 7.77937 2.1072 8.25565 2.1072 8.81132V15.5587H15.885V1.03196C15.885 0.476288 16.3713 0 16.9386 0ZM13.0079 10.4386C13.5752 10.4386 14.0615 10.9149 14.0615 11.4706C14.0615 11.7087 13.9399 11.9866 13.7373 12.185C13.5347 12.3835 13.2916 12.4629 13.0079 12.5026H5.14643C4.5791 12.5026 4.09283 12.0263 4.09283 11.4706C4.09283 10.9149 4.5791 10.4386 5.14643 10.4386H13.0079ZM13.0079 5.19947C13.5752 5.19947 14.0615 5.67576 14.0615 6.23143C14.021 6.50926 13.9399 6.74741 13.7373 6.94586C13.5347 7.14432 13.2916 7.2237 13.0079 7.26339H5.14643C4.5791 7.26339 4.09283 6.7871 4.09283 6.23143C4.09283 5.67576 4.5791 5.19947 5.14643 5.19947H13.0079ZM13.0079 0.0396906C13.5752 0.0396906 14.0615 0.515978 14.0615 1.07165C14.0615 1.30979 13.9399 1.58763 13.7373 1.78608C13.5347 1.98453 13.2916 2.06391 13.0079 2.1036H9.03664C8.46932 2.1036 7.98304 1.62732 7.98304 1.07165C7.98304 0.515978 8.46932 0.0396906 9.03664 0.0396906H13.0079Z" />
    </svg>
  )
}
