import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  shared: {
    VERCEL_URL: z
      .string()
      .optional()
      .transform(v => (v ? `https://${v}` : undefined)),
    PORT: z.coerce.number().default(3000),
  },

  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app isn't
   * built with invalid env vars.
   */
  server: {},

  /**
   * Specify your client-side environment variables schema here.
   * For them to be exposed to the client, prefix them with `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_SUPERSTASH_URL: z.string(),
    NEXT_PUBLIC_SUPERSTASH_APP_URL: z.string(),
    NEXT_PUBLIC_SUPERSTASH_TENANT_URL: z.string(),
    NEXT_PUBLIC_CANNY_APP_ID: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_HOST: z.string().url().min(1),
  },

  /**
   * Destructure all variables from `process.env` to make sure they aren't tree-shaken away.
   */
  experimental__runtimeEnv: {
    PORT: process.env.PORT,
    VERCEL_URL: process.env.VERCEL_URL,
    NEXT_PUBLIC_SUPERSTASH_URL: process.env.NEXT_PUBLIC_SUPERSTASH_URL,
    NEXT_PUBLIC_SUPERSTASH_APP_URL: process.env.NEXT_PUBLIC_SUPERSTASH_APP_URL,
    NEXT_PUBLIC_SUPERSTASH_TENANT_URL: process.env.NEXT_PUBLIC_SUPERSTASH_TENANT_URL,
    NEXT_PUBLIC_CANNY_APP_ID: process.env.NEXT_PUBLIC_CANNY_APP_ID,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  },

  skipValidation:
    !!process.env.CI ||
    !!process.env.SKIP_ENV_VALIDATION ||
    process.env.npm_lifecycle_event === "lint",
})

export const isProd =
  process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production"
export const isDev = !isProd
