"use client"

import Link from "next/link"
import { useSelectedLayoutSegment } from "next/navigation"
import type { ComponentProps, ReactNode } from "react"
import { cx } from "~/lib/cva"

export type NavItem = ComponentProps<"a"> & {
  title: ReactNode
  href: string
  segment?: string
}

type NavProps = ComponentProps<"nav"> & {
  items: NavItem[]
}

export const Nav = ({ className, items }: NavProps) => {
  const activeSegment = useSelectedLayoutSegment()

  return (
    <nav className={cx("flex items-start gap-x-4 lg:gap-x-6 gap-y-2", className)}>
      {items.map(({ title, href, segment, ...item }) => (
        <Link
          key={href}
          href={href}
          className={cx(
            "flex items-center p-0.5 -m-0.5 hover:text-foreground",
            activeSegment === segment ? "text-foreground" : "text-foreground/60",
          )}
          {...item}
        >
          {title}
        </Link>
      ))}
    </nav>
  )
}
