import Link from "next/link"
import { Button, type ButtonProps } from "~/components/ui/button"
import { env } from "~/env"

export const CtaButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button size="lg" asChild {...props}>
      <Link href={env.NEXT_PUBLIC_SUPERSTASH_APP_URL} target="_blank">
        {children ?? (
          <>
            Start building
            <span className="ml-1 font-medium opacity-75">— it's free</span>
          </>
        )}
      </Link>
    </Button>
  )
}
